.title {
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 10px 0;
}

.description {
  color: #757575;
  font-size: 18px;
  margin: 10px 0 15px 0;
}

.card {
  padding: 16px;
  width: 100% !important;
  height: auto;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  position: relative;
  display: flex !important;
  flex-direction: column !important;


  .card_title {
    font-size: 16px;
  }

  .card_subtitle {
    font-size: 14px;
    color: gray;
  }

}

.value_card {
  padding: 16px;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  min-height: 130px;
  justify-content: space-between;

  .card_value {
    font-size: 20px;
    font-weight: 600;
  }

  .card_remark {
    color: gray;
    font-size: 12px;
  }
}

.chart_btn {
  margin-left: 8px !important;
  min-width: 50px;
}


.learner_progress_card {
  align-items: start;
}

.learner_progress_wrapper {

  width: 100%;

  .learner_progress_root {
    height: 34px !important;
  }

  .learner_progress_bar1 {
    height: 22px !important;
    top: 6px !important;
    background-color: #4463cb !important;
  }

  .learner_progress_bar2 {
    background-color: #62cde3 !important;
  }
}


.circle_progress_card {
  align-items: start;
}

.circle_item {
  color: #757575;
  font-size: 14px;
}

.circle_descript {
  color: #9e9e9e;
  font-size: 14px;
}

.circle_item_point {
  margin-right: 5px;
}

.trendingup {
  color: #b0d780 !important;
}

.trendingdown {
  color: #ff6174 !important;
}

.learner_progress_info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: gray;
  font-size: 12px;

}

.learner_progress_bar1_point {
  color: #4463cb;
  margin-right: 5px;
}

.learner_progress_bar2_point {
  color: #62cde3;
  margin-right: 5px;
}


.summaryCard {
  position: relative;
  height: 104px;
  border-radius: 8px;
  padding: 8px 16px;
  overflow: hidden;
}

.summaryCard_title {
  margin: 0;
  color: white;
  font-size: 20px;
  line-height: 24px;
}

.summaryCard_data {
  margin: 0;
  font-size: 40px;
  line-height: 64px;
  color: white;
}

.summaryCard_icon {
  position: absolute;
  color: rgba($color: #fff, $alpha: 0.3);
  top: -18px;
  left: 48%;
}

.chartData_Container {
  max-height: calc(100vh - 220px);
  overflow: scroll;
}

.chartTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  width: 100%;
}

.markContainer {
  display: flex;
  width: 100%;
  justify-content: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin: 8px;
    }
  }
}

.DonutChartDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #9e9e9e;
}

.tip_popover {
  background-color: #424242 !important;
  color: white !important;
  padding: 5px 10px;
  margin-left: 3px;
}

.datePickerContainer {
  width: fit-content;
  position: relative;
  z-index: 100;
}

.CalendarBtn {
  cursor: pointer;
  right: 100%;
}

.LineMarkerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LineMarker {
  background-color: #ffc700;
  height: 5px;
  width: 18px;
  margin-right: 8px;
}

.LineMarker_dashed {
  display: flex;
  width: 18px;
  justify-content: space-between;
  margin-left: 24px;
  align-items: center;
  margin-right: 8px;

  div {
    background-color: #bdbdbd;
    width: 6px;
    height: 5px;
  }
}

.chartSubTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  color: #9e9e9e;
  top: 40%;
  left: 0px;
  transform: translateY(-50%) rotate(-90deg);
}

.barchartSubTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  color: #9e9e9e;
  top: 50%;
  left: -12px;
  transform: translateY(-50%) rotate(-90deg);
}

.chartTitle_container {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  align-items: center;
}

.tooltip_popper {
  border-radius: 4px;
}

.tooltip {
  background-color: #424242;
  font-size: 16px;
  white-space: pre-line;
}

.tooltip_icon {
  color: #9e9e9e;
}

.TooltipContainer {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  div {
    display: flex;
    align-items: center;
  }
}

.tooltipCircle {
  height: 12px;
  width: 12px;
  border-radius: 100px;
  margin-right: 12px;
  background-color: #bdbdbd;
}

.arrowBack {
  display: flex;
  align-items: center;
  color: #5bb6ff;
  width: fit-content;
  margin: 0 0 4px 0;
  cursor: pointer;
}

.tooltipText {
  font-size: 16px;
}

.EstablishBtn {
  position: absolute !important;
  background-color: #5bb6ff !important;
  color: white !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);

  &:hover {
    background-color: #008afa !important;
  }
}

.tab {
  color: #7000FF !important;
}



.table {
  width: 100%;
  margin-top: 10px;

  tr {
    height: 50px;
  }

  th,
  td {
    border-bottom: 1px solid #c5c5c5;
    padding: 5px
  }

  thead tr {
    background-color: #eee;
  }

  // tbody tr {
  //   background-color: #eee;
  // }

  th {
    text-align: left;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
  }

  tr {
    text-align: left;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr:hover {
    background-color: #eee;

  }


  tr:nth-child(even) {
    background: #f5f5f5
  }

}

.btn_del {
  color: #d50000 !important;
}

.btn_edit {
  color: #9e9e9e !important;
}

.btn_status {
  cursor: pointer;
}

.data_range_selector {
  margin: 0 !important;
}

.chart_btns{
  display: flex;
}