.container{
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.paginationBox{
    width: fit-content;
    align-items: center;
}

.warnText{
    margin: 8px 0 0 0;
    text-align: center;
    font-size: 15px;
    color: red;
}

@media screen and (max-width: 600px) {
    .container{
        display: block;
        text-align: center;
    }
    .pageBar{
        margin-top: 10px;
    }
}