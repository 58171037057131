.title {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    margin: 0 0 8px 0;
}

.subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #757575;
    margin: 0 0 24px 0;
}

.add_textbook_btn {
    background-color: #5BB6FF !important;
    margin-bottom: 12px !important;
    color: white !important;
}

.add_textbook_btn:hover {
    background-color: #0089f9 !important;
}

.back_btn {
    display: flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    color: #5BB6FF;
    align-items: center;
    cursor: pointer;
}
.listbox {
    display: grid;
    padding: 8px 16px;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    cursor: pointer;
  }
  
  .listbox > p {
    display: flex;
    align-items: center;
  }
  
  .listbox:hover {
    background-color: #f5f5f5;
  }
  .listItemTitle>p{
    margin: 0;
  }
  .listItemSubtext{
    font-size: 14px;
    color: #757575;
    justify-content: end;
    margin-bottom: 0;
  }
  .userAmount {
    color: #757575;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    justify-content: end;
  }
  
  .userAmount > span {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0 4px;
  }
  
  