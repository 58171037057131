.title {
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 10px 0;
}

.description {
  color: #757575;
  font-size: 18px;
  margin: 10px 0 15px 0;
}

.card {
  padding: 16px;
  width: 100% !important;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  margin-bottom: 15px !important;
  position: relative;
  display: flex !important;
  flex-direction: column !important;


  .card_title {
    font-size: 16px;
  }

  .card_subtitle {
    font-size: 14px;
    color: gray;
  }


}

.gard_card {
  padding: 5px 20px;
  width: 100% !important;
  background: #e5e7e9;
  border: 0;
  border-radius: 4px;
  position: relative;
  align-items: center;
  margin-bottom: 20px;

  .gard_card_icon {
    font-size: 16px;
  }

  .gard_card_list {
    font-size: 14px;
    color: gray;
  }


}

.table_wrapper {

  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.table {
  min-width: 100%;
  margin-top: 10px;

  tr {
    height: 50px;
  }

  th,
  td {
    border-bottom: 1px solid #c5c5c5;
    padding: 5px
  }

  th {
    background-color: #eee;
  }

  // tbody tr {
  //   background-color: #eee;
  // }

  th {
    text-align: left;
    color: #757575;
    background-color: #eee;
    font-size: 14px;
    font-weight: 400;
  }

  tr {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
  }

  td {
    background-color: white;
  }

  tbody tr:hover>td {
    background-color: #eee;

  }


  tr:nth-child(even)>td {
    background: #f5f5f5
  }

}

.btn_del {
  color: #d50000 !important;
}

.btn_edit {
  color: #9e9e9e !important;
}

.btn_status {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .table {
    border: 0;

    thead {
      display: none;
    }

    tr {
      position: relative;
      display: block;
      height: auto;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }


    td {
      border: 0;
      display: block;
      text-align: left;
      height: 40px;
      background: #f5f5f5;
    }

    td:before {
      color: #757575;
      margin-right: 5px;
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    .btn_del {
      display: none;
    }

    .btn_ {
      display: none;
    }

    .sticky_col {
      position: ㄐ;
    }

    .sticky_col_one {
      width: 100%;
    }

  }
}

.table_wrapper {
  min-width: 100%;
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.sticky_col {
  position: sticky;
}

.sticky_col_one {
  width: 100px;
  left: 0;
}