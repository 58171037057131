.title {
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 10px 0;
}

.description {
  color: #757575;
  font-size: 18px;
  margin: 10px 0 15px 0;
}

.card {
  padding: 16px;
  width: 100% !important;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  margin-bottom: 15px !important;
  position: relative;
  display: flex !important;
  flex-direction: column !important;


  .card_title {
    font-size: 20px;
    font-weight: 500;
  }

  .card_subtitle {
    font-size: 14px;
    color: gray;
  }

  .card_enterprise_info {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .card_enterprise_info_left {
    font-weight: 700;
    font-size: 24px;
    color: #616161;
  }

  .card_enterprise_info_right {
    font-size: 14px;
    color: gray;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
  }
}



.listbox {
  display: grid;
  padding: 8px 16px;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  cursor: pointer;
}

.listbox>p {
  display: flex;
  align-items: center;
}

.listbox:hover {
  background-color: #f5f5f5;
}

.listTitle {
  font-weight: 500;
  font-size: 1.5rem;
}

.listItemTitle>p {
  margin: 0;
}

.listItemSubtext {
  font-size: 14px;
  color: #757575;
  justify-content: end;
  margin-bottom: 0;
}

.userAmount {
  color: #757575;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  justify-content: end;
}

.userAmount>span {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 4px;
}


.card_title_box{
  display: flex;
  justify-content:space-between;
}


.edit_icon{
  padding: 16px !important;
}

.card_emailSet_Box{
  padding: 16px 0px 8px 0px;
}

.card_emailSet_text{
  font-size: 24px;
  font-weight: 700;
  color: #BDBDBD;
}


.card_emailSet_left{
  display: flex;
}

.card_emailSet_right{
  padding-left: 20px;
}

.emailSave_btn{
  background-color: black !important;
  color: #FFFFFF !important;
  font-size: 14px !important;
  padding: 6px 15px !important; 
}

.emailSave_btn_error{
  background-color: #BDBDBD !important;
  color: #FFFFFF !important;
}

.checkIcon{
  margin-left: 6px;
}

.emailAlertContainer{
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}


.emailSave_ok{
  display: flex;
  padding-top: 5px;
}

.emailSet_input{
  background-color: #EEEEEE !important;
}





.arrowBack {
  display: flex;
  align-items: center;
  color: #5bb6ff;
  width: fit-content;
  margin: 0 0 4px 0;
  cursor: pointer;
}

.packagae_btns {
  display: none;
}

.listbox {
  display: flex;
  min-height: 60px;
  flex-direction: row;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 10px 5px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  .listbox_left {
    min-width: 180px;
    display: flex;
    flex-direction: row;
  }

  .listbox_subtitle {
    font-size: 14px;
    color: #757575;
    justify-content: end;
    margin-bottom: 0;
  }

  .listbox_right {
    min-width: 180px;
    font-size: 14px;
    color: #757575;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}



.card_item_list {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
}

.listItemTitle>p {
  margin: 0;
}

.listItemSubtext {
  font-size: 14px;
  color: #757575;
  justify-content: end;
  margin-bottom: 0;
}

.listItemCollapse {
  padding-left: 90px;

}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.editDialog {
  min-width: 350px;
  min-height: 250px;
}

.datePicker {
  margin-left: 10px;
}

.listbox_details_info {
  display: flex;

  &>* {
    margin-left: 15px;
  }
}

.package_list {
  margin-top: 60px !important;
}

@media screen and (max-width: 680px) {
  .card_emailSet_text,
  .card_enterprise_info_left{
    font-size: 20px !important;
  }

  .card_enterprise_info{
    display: block !important;
    text-align: center;
  }
  
  .card_enterprise_info_right{
    justify-content: center;
  }
}


@media screen and (max-width: 450px) {
  .card_enterprise_info_right{
    display: block !important;
  }
  .card_emailSet_text,
  .card_enterprise_info_left{
    font-size: 16px !important;
  }

  .card_emailSet_right{
    padding-top: 10px;
    padding-left: 0px;
  }
}