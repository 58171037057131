@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);

body {
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Noto Sans TC" "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(143, 143, 143);
}

.react-datepicker__view-calendar-icon input {
  width: 140px;
  cursor: pointer;
  border-radius: 5px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0 !important;
  margin: 7px !important;
}

.react-datepicker__input-container {
  margin-left: 10px;
}

.react-datepicker__close-icon {
  margin-right: 10px;
}
