
.markContainer {
    display: flex;
    width: 100%;
    justify-content: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            margin: 8px;
        }
    }

}