.title {
  font-weight: 400;
  font-size: 24px;
  margin: 0 0 10px 0;
}

.description {
  color: #757575;
  font-size: 18px;
  margin: 10px 0 15px 0;
}

.card {
  padding: 16px;
  width: 100% !important;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  margin-bottom: 15px !important;
  position: relative;
  display: flex !important;
  flex-direction: column !important;


  .card_title {
    font-size: 16px;
  }

  .card_subtitle {
    font-size: 14px;
    color: gray;
  }

}


.table {
  width: 100%;
  margin-top: 10px;

  tr {
    height: 50px;
  }

  th,
  td {
    border-bottom: 1px solid #c5c5c5;
    padding: 5px
  }

  thead tr {
    background-color: #eee;
  }

  // tbody tr {
  //   background-color: #eee;
  // }

  th {
    text-align: left;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
  }

  tr {
    text-align: left;
    color: #757575;
    font-size: 16px;
    font-weight: 400;
  }

  tbody tr:hover {
    background-color: #eee;

  }


  tr:nth-child(even) {
    background: #f5f5f5
  }

}

.btn_del {
  color: #d50000 !important;
}

.btn_edit {
  color: #9e9e9e !important;
}

.btn_status {
  cursor: pointer;
}

.only_mobile {
  display: none;
}


@media screen and (max-width: 600px) {
  .table {
    border: 0;

    thead {
      display: none;
    }

    tr {
      position: relative;
      display: block;
      height: auto;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }


    td {
      border: 0;
      display: block;
      text-align: left;
      height: 40px;
      background: #f5f5f5;
    }

    td:before {
      color: #757575;
      margin-right: 5px;
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:not(:first-child) {
      padding-left: 50px;
      padding-top: 15px;
    }

    td:first-child {
      display: block;
      float: left;
      width: 40px;
    }

    .btn_del {
      display: none;
    }

    .btn_ {
      display: none;
    }

    .only_mobile {
      display: block;
    }

    .only_pc {
      display: none;
    }

  }
}

.datepicker {
  margin: 0 !important;
}

.datepickerTo {
  align-self: center;
}