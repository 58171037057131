.sidebar {
    padding-top: 55px;
}

.main_menu {
    margin-top: 10px !important;
    padding: 0 0 0 15px !important
}

.btn_sub_menu {
    padding-left: 48px !important;

}

.btn_sub_menu_selected {
    background-color: black !important;
    color: white !important;

    .txt_sub_menu::after {
        content: ">";
        position: absolute;
        font-family: monospace;
        left: 30px;
    }
}

.enterpriseName {
    text-align: center;
    font-size: 20px;
    min-height: 30px;
    margin: 25px 5px 0 5px;
}

.select{
    font-size: 14px;
}