.returnBar{
    display: flex;
    align-items: center;
}

.returnText{
    cursor: pointer;
    text-decoration: none;
    color: #5BB6FF;
}

.titleBar{
    display: flex;
    align-items: center;
  h1{
    font-weight: 400;
    margin: 0px;
    margin-left: 8px;
  }
}

.card {
    padding: 16px;
    width: 100% !important;
    background: #FFFFFF;
    border: 0;
    border-radius: 4px;
    margin-bottom: 15px !important;
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    margin-top: 15px !important;
}

.emptyDataTable{
  text-align: center;
  padding: 30px 0px !important;
}

.table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
  
    tr {
      height: 50px;
    }
  
    th,
    td {
      border-bottom: 1px solid #c5c5c5;
      padding: 5px
    }
  
    thead tr {
      background-color: #eee;
    }
  
    th {
      text-align: left;
      color: #757575;
      font-size: 14px;
      font-weight: 400;
    }
  
    tr {
      text-align: left;
      color: #757575;
      font-size: 16px;
      font-weight: 400;
    }
  
    tbody tr:hover {
      background-color: #eee;
  
    }

    th:first-child {
        padding-left: 28px;
    }

    td:first-child {
        padding-left: 28px;
    }

    tr:nth-child(even) {
      background: #f5f5f5
    }
  
  }

.header_action {
    width: 100%;
    display: block;
    cursor: pointer;
    color: inherit;
}
  
.hide_action {
    display: none;
}
  
.mouseover_hide_action {
    opacity: 0;
}

@media screen and (max-width: 600px) {
    .table {
      border: 0;
  
      thead {
        display: none;
      }
  
      tr {
        position: relative;
        display: block;
        height: auto;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
  
  
      td {
        border: 0;
        display: block;
        text-align: left;
        height: 40px;
        background: #f5f5f5;
        padding: 10px 20px;

      }
  
      td:before {
        color: #757575;
        margin-right: 5px;
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      td:first-child {
        padding-left: 20px;
      }
    }
    .titleBar{
        display: block;
    }
    
}