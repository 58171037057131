.btn_add {
    background-color: black;

    &:hover {
        background-color: black;

    }


}

.formGroup {
    align-items: center;
}

.success_helper {
    color: #207868 !important;
}

.expireTime {
    margin: 0;
}

.btn_enable_password {
    margin-bottom: 30px;
}

.rescue_msg {
    color: red;
}