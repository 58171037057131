.pagetitle {
  font-family: "Noto Sans TC";
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 25px;
  margin-bottom: 24px;
}

.chatArea {
  margin: auto;
  position: relative;
  width: 100%;
  cursor: grab;

  .tick line {
    stroke-opacity: 0.5;
    stroke-dasharray: 2, 2;
  }
}

.chatarea_btngroup {
  right: 20px;
  top: 20px;
  position: absolute;
}

.node {
  border-color: #e0e0e0;
  fill: #e0e0e0;
  outline-color: #e0e0e0;
  cursor: pointer;
  position: relative;
}

.node_main {
  border: 1px solid #e0e0e0;
  border: 6px solid #e0e0e0;
  outline: 5px solid #e0e0e0;
  outline-offset: 4px;
  border-radius: 50%;
}

.node_high {
  border-color: #afd281;
  fill: #afd281;
  outline-color: #afd281;
}

.node_medium {
  border-color: #e8c035;
  fill: #e8c035;
  outline-color: #e8c035;
}

.node_low {
  border-color: #f8a4a4;
  fill: #f8a4a4;
  outline-color: #f8a4a4;
}

.link {
  stroke: #e0e0e0;
  stroke-width: 2px;
}

.link_high {
  stroke: #afd281;
}

.link_medium {
  stroke: #e8c035;
}

.link_low {
  stroke: #f8a4a4;
}

.node_text {
  display: none;
}

.node_text_main {
  display: block;
}

.node_text_focus {
  display: block;
}

.chart_y_txt {
  font-size: 12px;
  letter-spacing: 0.28em;
  fill: #ababab;
  writing-mode: vertical-lr;
  font-family: "Noto Sans TC";
  font-weight: 300;
}
