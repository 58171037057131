.btn_login {
  margin-top: 24px !important;

  // &:hover {
  //   background-color: !important;
  // }
}

.logoBar{
  display: flex;
  justify-content:space-between
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

.login_title {
  display: flex;
  flex-direction: column;

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.errorlineColor {
  color: red !important;
  height: 40px !important;
}

.lineColor {
  color: #757575 !important;
  height: 40px !important;
}

.loading_row {
  justify-content: center;
  width: 100%;
  display: flex;
}

.backgroundImg {
  position: fixed;
  transform: translate(-850px, -350px) scale(1.1);
}

.TabColor {
  color: #757575;
}

.TabColor:active {
  color: #5bb6ff !important;
}

.container {
  height: 100vh;
  overflow: hidden;
  padding: 10px;
  padding-top: 80px;

  justify-content: center;
  align-items: center;
  background-image: url('../../assets/pic_login_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right -40rem top 50%;
}

.platform_name {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}