.TopbarContainer {
  height: 64px;
}

.logoWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 3rem;
  width: 3rem;
  object-fit: contain;
}

.logo_txt {
  color: #757575;
}

.defaultEnterprise.defaultEnterprise {
  color: #757575;
  height: 50px;
  border-bottom: solid 0.5px #bdbdbd;
  display: flex;
  align-items: baseline;
}

.platform_name {

  color: black;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.btn_menu_icon {
  width: 40px;
  height: 40px;
}

.menu_icon {
  position: relative;
  color: black;
}

.toolbar_left {
  display: flex;
  align-items: center;
  align-content: flex-start;
}

.toolbar {
  max-height: 64px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.btn_logout {
  background-color: black !important;
  color: white !important;
}

.toolbar_right{
  display: flex;
  
}

.btn_changeLanguage{
  margin-right: 10px !important;
}

.select{
  font-size: 14px;
}