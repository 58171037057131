.pagetitle {
   font-family: "Noto Sans TC";
   margin: 0;
   font-style: normal;
   font-weight: 400;
   font-size: 24px;
   line-height: 32px;
   margin-top: 25px;
   margin-bottom: 24px;
}

.chatArea {
   margin: auto;
   position: relative;
   width: 100%;
   cursor: grab;

   .tick line {
      stroke-opacity: 0.5;
      stroke-dasharray: 2, 2;
   }

}




.chatarea_btngroup {
   right: 20px;
   top: 20px;
   position: absolute;
}

.node {

   border-color: #E0E0E0;
   fill: #E0E0E0;
   outline-color: #E0E0E0;
   cursor: pointer;
   position: relative;
}

.node_main {
   border: 1px solid #E0E0E0;
   border: 6px solid #E0E0E0;
   outline: 5px solid #E0E0E0;
   outline-offset: 4px;
   border-radius: 50%;
}

.node_high {
   border-color: #AFD281;
   fill: #AFD281;
   outline-color: #AFD281;
}

.node_medium {
   border-color: #E8C035;
   fill: #E8C035;
   outline-color: #E8C035;
}



.node_low {
   border-color: #F8A4A4;
   fill: #F8A4A4;
   outline-color: #F8A4A4;
}

.link {

   stroke: #E0E0E0;
   stroke-width: 2px;
}

.link_high {
   stroke: #AFD281;
}

.link_medium {
   stroke: #E8C035;
}

.link_low {
   stroke: #F8A4A4;
}

.node_text {
   display: none;
}

.node_text_main {
   display: block;
}

.node_text_focus {
   display: block;
}

.chart_y_txt {
   font-size: 12px;
   letter-spacing: 0.28em;
   fill: #ababab;
   writing-mode: vertical-lr;
   font-family: "Noto Sans TC";
   font-weight: 300;
}