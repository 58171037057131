.dev_banner_topRight,
.dev_banner_bottomLeft,
.dev_banner_topLeft,
.dev_banner_bottomRight{
  position: fixed;
  color: #f01932;
  padding: 0px 5px;
  z-index: 1000000000000;
  font-size: 12px;
}

.dev_banner_topRight {
  top: 0;
  right: 0;
}

.dev_banner_bottomLeft {
  bottom: 0;
  left: 0;
}

.dev_banner_topLeft {
  top: 0;
  left: 0;
}

.dev_banner_bottomRight {
  bottom: 0;
  right: 0;
}

